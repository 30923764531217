<template>
  <div id="my-app" class="page-wrapper">

    <app-header v-bind:shoppingCount="shoppingCount" />
    
    <div class="box" v-cloak>
      <div class="site-content">
        <router-view @countRefresh="countRefresh"/>
      </div>
    </div>

    <div style="height:60px;"></div>
    <a href="https://coinwink.com" target="_blank" >
      <div class="ma-banner gradient-border" title="Cryptocurrency Price Alerts" alt="Cryptocurrency Price Alerts" v-if="$route.name != 'nft'">
            <div class="banner-link" >Crypto Alerts</div>
      </div>
    </a>

    <div style="clear:both;height:90px;"></div>

    <app-footer/>

    <!-- Credit card SVG icons -->
    <svg style="display: none">
    <defs>
      
      <symbol id="portfolio-profitloss" viewBox="0 0 134.69 109.17">
          <title>Profit/Loss</title>
          <path style="fill:#8A8A8A" d="M165.63,400.74h26a6.62,6.62,0,0,1,4.69,1.94v0a6.6,6.6,0,0,1,1.94,4.67v37.17a6.64,6.64,0,0,1-6.63,6.63h-26a6.6,6.6,0,0,1-4.67-1.94h0a6.61,6.61,0,0,1-1.95-4.69V407.37a6.6,6.6,0,0,1,1.95-4.67l0,0a6.6,6.6,0,0,1,4.67-1.94h0Zm95.4-41.4h26a6.61,6.61,0,0,1,4.69,1.94v0a6.6,6.6,0,0,1,1.94,4.67v78.57a6.64,6.64,0,0,1-6.63,6.63H261a6.6,6.6,0,0,1-4.67-1.94h0a6.61,6.61,0,0,1-1.94-4.69V366a6.6,6.6,0,0,1,1.94-4.67l0,0a6.6,6.6,0,0,1,4.67-1.94h0Zm24.66,8H262.4v75.83h23.29V367.34h0ZM213.33,342h26a6.61,6.61,0,0,1,4.69,1.94v0a6.6,6.6,0,0,1,1.94,4.67v95.91a6.64,6.64,0,0,1-6.63,6.63h-26a6.6,6.6,0,0,1-4.67-1.94h0a6.61,6.61,0,0,1-1.94-4.69V348.63a6.6,6.6,0,0,1,1.94-4.67l0,0a6.6,6.6,0,0,1,4.67-1.94h0ZM238,350H214.7v93.17H238V350h0Zm-47.7,58.74H167v34.43h23.29V408.74h0Z" 
          transform="translate(-159 -342)"/>
      </symbol>

      <symbol id="portfolio-note" viewBox="0 0 134.69 109.17">
          <title>Note</title>
          <path style="fill:#8A8A8A" d="M364.79,421.29a4,4,0,1,1,0-8H402a4,4,0,0,1,0,8H364.79ZM352.71,342h89.92a12.53,12.53,0,0,1,12.51,12.51v84.16a12.53,12.53,0,0,1-12.51,12.51H352.71a12.53,12.53,0,0,1-12.51-12.51V354.51A12.53,12.53,0,0,1,352.71,342h0Zm89.92,8H352.71a4.53,4.53,0,0,0-4.51,4.51v84.16a4.53,4.53,0,0,0,4.51,4.51h89.92a4.53,4.53,0,0,0,4.51-4.51V354.51a4.53,4.53,0,0,0-4.51-4.51h0Zm-77.83,29.89a4,4,0,1,1,0-8h65.75a4,4,0,1,1,0,8H364.79Zm0,20.7a4,4,0,1,1,0-8h65.75a4,4,0,1,1,0,8H364.79Z" 
          transform="translate(-340.2 -342)"/>
      </symbol>

      <symbol id="svg-alert-delete" viewBox="0 0 12.45 12.45">
          <title>Delete</title>
          <path d="M299.6,390.6l11.25,11.25m0-11.25L299.6,401.85" transform="translate(-299 -390)" 
          style="fill:none;stroke:#888888;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px"/>
      </symbol>

    </defs>
    </svg>

  </div>
</template>

<script>
import Header from "./components/partials/Header.vue";
import Footer from "./components/partials/Footer.vue";

import Home from './views/Home.vue';

export default {
  name: 'App',
  data() {
    return {
      inner: '',
      complement: 'Crypto Clothing Store',
      separator: '-',

      shoppingCount: 0,
      showLoader: true,
      coins: cc_coins,
      version: 'v0.7.1-beta',
      products: {

        'cc-tshirt': {
          'name': 'T-Shirt',
          'sizesPrices': {
            'S': 22,
            'M': 22,
            'L': 22,
            'XL': 22,
            '2XL': 23,
            '3XL': 25,
          },
          'colors': {
            'White': 'White',
            'Black': 'Black'
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-hodl-tshirt': {
          'name': 'HODL T-Shirt',
          'sizesPrices': {
            'S': 24,
            'M': 24,
            'L': 24,
            'XL': 24,
            '2XL': 25,
            '3XL': 26,
          },
          'colors': {
            'Black': 'Black',
            'White': 'White'
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-laptop-sleeve': {
          'name': 'Crypto Laptop Case (Sleeve)',
          'sizesPrices': {
            '13in': 36,
            '15in': 38,
          },
          'colors': {
            'Black': 'Black',
            'White': 'White'
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-dress': {
          'name': 'Dress',
          'sizesPrices': {
            'XS': 44,
            'S': 44,
            'M': 44,
            'L': 44,
            'XL': 44,
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-hoodie': {
          'name': 'Hoodie',
          'sizesPrices': {
            'S': 42,
            'M': 42,
            'L': 42,
            'XL': 42,
            '2XL': 44,
            '3XL': 46,
            '4XL': 46,
            '5XL': 46
          },          
          'colors': {
            'White': 'White',
            'Black': 'Black'
          },
          'shipping': {
            '1st': 7,
            'extra': 2,
          }
        },

        'cc-sweatshirt': {
          'name': 'Sweatshirt',
          'sizesPrices': {
            'S': 37,
            'M': 37,
            'L': 37,
            'XL': 37,
            '2XL': 39,
            '3XL': 41,
            '4XL': 42,
            '5XL': 43
          },          
          'colors': {
            'White': 'White',
            'Black': 'Black'
          },
          'shipping': {
            '1st': 7,
            'extra': 2,
          }
        },
        
        'cc-longsleeve-tshirt': {
          'name': 'LongsleeveTshirt',
          'sizesPrices': {
            'XS': 36,
            'S': 36,
            'M': 36,
            'L': 36,
            'XL': 36,
            '2XL': 39,
          },          
          'colors': {
            'White': 'White',
            'Black': 'Black'
          },
          'shipping': {
            '1st': 7,
            'extra': 2,
          }
        },

        'cc-mug': {
          'name': 'Coffee Mug',
          'sizesPrices': {
            '11oz': 17,
            '15oz': 22
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-sticker': {
          'name': 'Sticker',
          'sizesPrices': {
            '4x4': 8,
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-backpack': {
          'name': 'Backpack',
          'sizesPrices': {
            'Standard': 67,
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-mask': {
          'name': 'Mask',
          'sizesPrices': {
            'Standard': 25,
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          }
        },

        'cc-tote-bag': {
          'name': 'Crypto Tote Bag',
          'sizesPrices': {
            '15x15': 31,
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          },
          'colors': {
            'Black': 'Black',
            'White': 'White'
          },
        },

      }
    }
  },

  components: {
    appHeader: Header,
    appFooter: Footer,
    Home
  },

  head: {
    title: function () {
      return {
        inner: this.inner,
        separator: this.separator,
        complement: this.complement
      }
    },
  },

  beforeMount() {
    let self = this;
    dexie.products.toArray(function(products) {
      products.forEach ( product => {
        self.shoppingCount += +product.item_amount;
      });
    })
    .catch(e => {console.log('shoppingCount', e)} );
  },

  mounted() {
    // if (to.name == 'CryptoToteBag') {
    //   this.inner =  "Crypto Tote Bag";
    //   this.$emit('updateHead');
    // }
    // if (to.name == 'CryptoLaptopSleeve') {
    //   this.inner =  "Crypto Laptop Case (Sleeve)";
    //   this.$emit('updateHead');
    // }

    if (this.$route.name == 'Home') {
      this.titleHome();
    }
    else if (this.$route.name == 'ShoppingCart') {
      this.titleShoppingCart();
    }
    else if (this.$route.name == "CryptoLogosPoster") {
      this.titleCryptoLogosPoster();
    }
    else if (this.$route.name == "CryptoFashion") {
      this.titleCryptoFashion();
    }
    else if (this.$route.name == "SingleApocalypseWow") {
      this.titleSingleApocalypseWow();
    }
    // from CoinClothing.vue
    else if (this.$route.name == 'CoinClothing') {
      this.titleCoinClothing();
    }
    else if (this.$route.name == 'CoinClothingTshirt') {
      this.titleCoinClothingTshirt();
    }
    else if (this.$route.name == 'CoinClothingHodlTshirt') {
      this.titleCoinClothingHodlTshirt();
    }
    else if (this.$route.name == 'CoinClothingDress') {
      this.titleCoinClothingDress();
    }
    else if (this.$route.name == 'CoinClothingHoodie') {
      this.titleCoinClothingHoodie();
    }
    else if (this.$route.name == 'CoinClothingMug') {
      this.titleCoinClothingMug();
    }
    else if (this.$route.name == 'CoinClothingSticker') {
      this.titleCoinClothingSticker();
    }
    else if (this.$route.name == 'CoinClothingBackpack') {
      this.titleCoinClothingBackpack();
    }
    else if (this.$route.name == 'CoinClothingMask') {
      this.titleCoinClothingMask();
    }

    else if (this.$route.name == 'CoinClothingSweatshirt') {
      this.titleCoinClothingSweatshirt();
    }
    else if (this.$route.name == 'CoinClothingLongsleeveTshirt') {
      this.titleCoinClothingLongsleeveTshirt();
    }
    else if (this.$route.name == 'CoinClothingLaptopSleeve') {
      this.titleCoinClothingLaptopSleeve();
    }

    // from Page.vue
    else if (this.$route.name == 'faq') {
      this.titleFAQ();
    }
    else if (this.$route.name == 'about') {
      this.titleAbout();
    }
    else if (this.$route.name == 'privacy-policy') {
      this.titlePrivacy();
    }
    else if (this.$route.name == 'terms-and-conditions') {
      this.titleTerms();
    }
    else if (this.$route.name == 'shipping') {
      this.titleShipping();
    }
    else if (this.$route.name == 'contacts') {
      this.titleContacts();
    }
    else if (this.$route.name == 'payment-success') {
      this.titlePaymentSuccess();
    }
    else if (this.$route.name == 'payment-declined') {
      this.titlePaymentDeclined();
    }
    // from PageCategory.vue
    else if (this.$route.name == 'CryptoDresses') {
      this.titleCryptoDresses();
    }
    else if (this.$route.name == 'CryptoTshirts') {
      this.titleCryptoTshirts();
    }
    else if (this.$route.name == 'CryptoStickers') {
      this.titleCryptoStickers();
    }
    else if (this.$route.name == 'CryptoHoodies') {
      this.titleCryptoHoodies();
    }
    else if (this.$route.name == 'CryptoMugs') {
      this.titleCryptoMugs();
    }
    else if (this.$route.name == 'CryptoBackpacks') {
      this.titleCryptoBackpacks();
    }
    else if (this.$route.name == 'CryptoMasks') {
      this.titleCryptoMasks();
    }
    
    else if (this.$route.name == 'CryptoLongsleeveTshirts') {
      this.titleCryptoLongsleeveTshirts();
    }
    else if (this.$route.name == 'CryptoSweatshirts') {
      this.titleCryptoSweatshirts();
    }
    else if (this.$route.name == 'CryptoLaptopSleeves') {
      this.titleCryptoLaptopSleeves();
    }

    if (this.$route.name == "payment-success") {
      dexie.products.clear();
      this.countRefresh();
    }
  },

  watch: {     
    $route (to, from){
      if (to.name == 'Home') {
        this.titleHome();
      }
      else if (to.name == 'ShoppingCart') {
        this.titleShoppingCart();
      }
      else if (to.name == 'CryptoLogosPoster') {
        this.titleCryptoLogosPoster();
      }
      else if (to.name == 'CryptoFashion') {
        this.titleCryptoFashion();
      }
      else if (to.name == 'SingleApocalypseWow') {
        this.titleSingleApocalypseWow();
      }
      // from CoinClothing.vue
      else if (to.name == 'CoinClothing') {
        this.titleCoinClothing();
      }
      else if (to.name == 'CoinClothingTshirt') {
        this.titleCoinClothingTshirt();
      }
      else if (to.name == 'CoinClothingHodlTshirt') {
        this.titleCoinClothingHodlTshirt();
      }
      else if (to.name == 'CoinClothingDress') {
        this.titleCoinClothingDress();
      }
      else if (to.name == 'CoinClothingHoodie') {
        this.titleCoinClothingHoodie();
      }
      else if (to.name == 'CoinClothingMug') {
        this.titleCoinClothingMug();
      }
      else if (to.name == 'CoinClothingSticker') {
        this.titleCoinClothingSticker();
      }
      else if (to.name == 'CoinClothingBackpack') {
        this.titleCoinClothingBackpack();
      }
      else if (to.name == 'CoinClothingMask') {
        this.titleCoinClothingMask();
      }

      else if (to.name == 'CoinClothingSweatshirt') {
        this.titleCoinClothingSweatshirt();
      }
      else if (to.name == 'CoinClothingLongsleeveTshirt') {
        this.titleCoinClothingLongsleeveTshirt();
      }
      else if (to.name == 'CoinClothingLaptopSleeve') {
        this.titleCoinClothingLaptopSleeve();
      }

      // from Page.vue
      else if (to.name == 'faq') {
        this.titleFAQ();
      }
      else if (to.name == 'about') {
        this.titleAbout();
      }
      else if (to.name == 'privacy-policy') {
        this.titlePrivacy();
      }
      else if (to.name == 'terms-and-conditions') {
        this.titleTerms();
      }
      else if (to.name == 'shipping') {
        this.titleShipping();
      }
      else if (to.name == 'contacts') {
        this.titleContacts();
      }
      else if (to.name == 'payment-success') {
        this.titlePaymentSuccess();
      }
      else if (to.name == 'payment-declined') {
        this.titlePaymentDeclined();
      }
      // from PageCategory.vue
      else if (to.name == 'CryptoDresses') {
        this.titleCryptoDresses();
      }
      else if (to.name == 'CryptoTshirts') {
        this.titleCryptoTshirts();
      }
      else if (to.name == 'CryptoStickers') {
        this.titleCryptoStickers();
      }
      else if (to.name == 'CryptoHoodies') {
        this.titleCryptoHoodies();
      }
      else if (to.name == 'CryptoCoffeeMugs') {
        this.titleCryptoMugs();
      }
      else if (to.name == 'CryptoBackpacks') {
        this.titleCryptoBackpacks();
      }
      else if (to.name == 'CryptoMasks') {
        this.titleCryptoMasks();
      }
      else if (to.name == 'CryptoLongsleeveTshirts') {
        this.titleCryptoLongsleeveTshirts();
      }
      else if (to.name == 'CryptoSweatshirts') {
        this.titleCryptoSweatshirts();
      }
      else if (to.name == 'CryptoLaptopSleeves') {
        this.titleCryptoLaptopSleeves();
      }
    }
  },

  methods: {
    titleSingleApocalypseWow() {
      this.inner =  "Dogecoin (DOGE) Apocalypse WOW Meme T-Shirt";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCryptoLogosPoster() {
      this.inner =  "Crypto Logos Poster";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleHome() {
      this.inner =  "Crypto Clothing";
      this.separator = "-";
      this.complement = "Cryptocurrency Merchandise Store";
      this.$emit('updateHead');
    },
    titleShoppingCart() {
      this.inner =  "Shopping Cart";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCryptoFashion() {
      this.inner =  "Cryptocurrency Fashion";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    // from CoinClothing.vue
    titleCoinClothing() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Clothing / Apparel / Merchandise / Accessories";
      this.$emit('updateHead');
    },
    titleCoinClothingTshirt() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "T-Shirt - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingHodlTshirt() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "HODL T-Shirt - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingDress() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "All-Over Print Dress - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingHoodie() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Hoodie - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingMug() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Coffee Mug - Crypto Accessories";
      this.$emit('updateHead');
    },
    titleCoinClothingSticker() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Kiss Cut Vinyl Sticker - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingBackpack() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Backpack - Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCoinClothingMask() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Face Mask - Crypto Clothing";
      this.$emit('updateHead');
    },

    titleCoinClothingSweatshirt() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Sweatshirt - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingLongsleeveTshirt() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Long Sleeve T-Shirt - Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCoinClothingLaptopSleeve() {
      this.inner = this.coins[this.$route.params.coinId].name+' ('+this.coins[this.$route.params.coinId].symbol+')';
      this.separator = " ";
      this.complement = "Laptop Sleeve (Case) - Crypto Clothing";
      this.$emit('updateHead');
    },

    // from Page.vue
    titleFAQ() {
      this.inner =  "FAQ";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleAbout() {
      this.inner = "About";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titlePrivacy() {
      this.inner = "Privacy Policy";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleTerms() {
      this.inner = "Terms and Conditions";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleShipping() {
      this.inner = "Shipping";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleContacts() {
      this.inner = "Contacts";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titlePaymentSuccess() {
      this.inner = "Payment Successful";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titlePaymentDeclined() {
      this.inner = "Payment Declined";
      this.separator = "-";
      this.complement = "Crypto Clothing";
      this.$emit('updateHead');
    },
    titleCryptoDresses() {
      this.inner =  "Crypto Dresses";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoTshirts() {
      this.inner =  "Crypto T-Shirts";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoStickers() {
      this.inner =  "Crypto Stickers";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoHoodies() {
      this.inner =  "Crypto Hoodies";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoMugs() {
      this.inner =  "Crypto Mugs";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoBackpacks() {
      this.inner =  "Crypto Backpacks";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoMasks() {
      this.inner =  "Crypto Face Masks";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    
    titleCryptoLongsleeveTshirts() {
      this.inner =  "Crypto Long Sleeve T-Shirts";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoSweatshirts() {
      this.inner =  "Crypto Sweatshirts";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },
    titleCryptoLaptopSleeves() {
      this.inner =  "Crypto Laptop Cases (Sleeves)";
      this.separator = "-";
      this.complement = "Crypto Clothing Store";
      this.$emit('updateHead');
    },

    countRefresh() {
      // console.log(0);
      let self = this;
      let shoppingCountTemp = 0;
      
      dexie.products.toArray(function(products) {
        products.forEach ( product => {
          shoppingCountTemp += +product.item_amount;
        });
      })
      .then(function () { self.shoppingCount = shoppingCountTemp; shoppingCountTemp = 0; })
      .catch(e => {console.log('shoppingCountTemp', e)} );
    }
  },

};
</script>

<style>
html {
  position: relative;
  min-height: 100%;
}

body {
  height:100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-size: 14px;
}

#app {
  height: 100%;
}

[v-cloak] {display: none}

* {
  /* box-sizing: content-box; */
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
}

.svg-logo {
  -webkit-filter: drop-shadow( 0px 1px 1px rgba(0, 0, 0, .4));
  filter: drop-shadow( 0px 1px 1px rgba(0, 0, 0, .4));
  /* Similar syntax to box-shadow */
}

/* progress-bar, site-content, site-footer, site-header */

.site-content {
  margin: 0 auto;
  margin-top: 50px;
  /* margin-bottom: 70px; */
  padding-left: 2px;
  padding-right: 2px;

  @media (--desktop) {
    max-width: 1200px;
  }

}

@media screen and (max-width:680px) {
  .site-content {
    padding-left:10px;
    padding-right:10px;
  }
  .desktop-only {
    display: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 3px;
  z-index: 1000000;
}

.loader .progress-bar {
  background: var(--brandTertiary);
  height: 3px;
  transition: all 0.25s;
}

.loader-animation-leave-active {
  transition: delay 1s;
}





.button-new {
  float:right;
  width:22px;
  height:22px;
  margin-right:30px;
  margin-top:21px;
}



.top {
  position: absolute;
  height: 5px;
  width: 100%;
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%);
}

/* Animated HAMBURGER menu */

.menu-wrapper {
  position: relative;
  float: right;
  width: 24px;
  top: 5px;
  height: 40px;
  cursor: pointer;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 24px;
  height: 3px;
}

.hamburger-menu {
  position: relative;
  transform: translateY(25px);
  background: #757575;
  transition: all 0ms 150ms;
}
.hamburger-menu.animate {
  background: #F1F1F2;
}

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  background: #757575;
  transition: bottom 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1), transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  background: #757575;
  transition: top 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1), transform 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 150ms cubic-bezier(0.23, 1, 0.32, 1), transform 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 150ms cubic-bezier(0.23, 1, 0.32, 1), transform 150ms 150ms cubic-bezier(0.23, 1, 0.32, 1);
}

/* End of animated HAMBURGER menu */


/* --- */
/* --- */
/* --- */

/* Typography */

body {
  font-family: 'Work Sans', sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  color: #222;
  line-height: 23px;
}

::placeholder {
  color: rgb(202, 202, 202);
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 13px;
}

.link-basic {
  color: rgb(0, 174, 255);
  cursor: pointer;
}

.link-basic:hover {
  text-decoration: underline;
}

.font-smaller {
  font-size: 14px;
}

.stress-toy-div-text { 
  float:left;
  margin-left:4px;
  padding-top:0px; 
}

.small {
  font-size: 13px;
}

.strike {
  text-decoration: line-through;
}

.color-orange {
  color: orange;
}

a, a:link, a:visited, a:active, a:hover {
  color: #222;
  text-decoration: none;
  cursor: pointer;
  /* user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  margin-bottom:10px; */
  /* border-bottom: 2px solid #6ca8c580; */
}

/* a:hover {
  text-decoration: underline;
} */

.link {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  margin-bottom: 0px!important;
  border-bottom: 2px solid #6ca8c580;
}

.link-icon {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

.select-none {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.underline {
  border-bottom: 1px solid grey;
}

.active:active {
  border-bottom: 1px solid grey;
}

h1 {
  font-size: 28px;
  margin-bottom: 30px;
}

h2 {
  font-size: 21px;
  margin-bottom: 10px;
  line-height:120%;
}

h3 {
  padding-top: 10px;
  font-size: 23px;
  line-height: 120%;
  font-weight: 300;
  color: #666666;
  font-family: 'Montserrat', sans-serif;
  padding-top: 25px;
}

h4 {
  padding-top: 15px;
}

h5 {
  font-size: 16px;
  font-weight: 300;
  color: #dddddd;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
}

/* Home */


.button-payment-disabled, .button-payment-disabled:hover {
  background-color: #818181!important;
  cursor: default!important;
}




/* Cardinity checkout - Vue component
============================================ */

.payment-container {
  border: 2px solid #e0e0e0;
  padding: 15px;
  padding-top: 25px;
  padding-bottom: 35px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  background-color: white;
  line-height: 130%;
  float: left;
}

.payment-inner {
  max-width: 310px;
}

@media screen and (max-width:600px) {
  .payment-inner-card {
    clear:both;
    float: left!important;
    margin-top: 20px;
  }
}

select {
  height: 24px;
}

.payment-inner input, select {
  font-family: 'Arial', 'Helvetica', sans-serif;
}

.payment-inner-address input {
  padding-left: 5px;
}

.cc-icons {
  float: left;
  margin-left: 8px;
}

.payment-inner-address {
  line-height: 30px;
}

.dotted-line {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 6px;
  border-bottom: 1px dotted #000000;
}

.input-basic {
  width: 225px;
}

.address-errors {
  color: red;
  font-family: 'Arial', 'Helvetica', sans-serif;
}

.search-div {
  width:220px;
  height:33px;
  border:1px solid #8e8e8e;
  font-size:17px!important;
  border-radius:2px;
  margin:0 auto;
}

.search-input {
  width:200px;
  height:25px;
  font-size:17px!important;
  border:0px;
  padding:4px;
  background:transparent;
}

input,
input::-webkit-input-placeholder {
  font-size: 17px;
}

.search-input:focus {
  outline: none;
}

.flex-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px;
}

/* homepage thumbs */
.flex-grid .col {
  width: 15%;
  margin-bottom: 30px;
  height: 80px;
  font-size:13px;
  line-height:15px;
  text-align: center;
  padding: 5px;
}

@media screen and (max-width: 540px) {
  .spacer-home {
    height:7px;
  }
}

@media screen and (max-width: 540px) {
  .product-content {
    clear:both;
    float:left;
    padding-top:15px;
    padding-left:0px!important;
  }
}

@media screen and (max-width: 500px) {
  .flex-grid .col {
    width: 18%;
  }
}

@media screen and (max-width: 400px) {
  .flex-grid .col {
    width: 23%;
    margin-bottom: 35px;
  }

}

@media screen and (max-width: 300px) {
  .flex-grid {
    padding-left: 25px;
    padding-right: 25px
  }
  .flex-grid .col {
    width: 31%;
  }
}

@media screen and (max-width: 250px) {
  .flex-grid .col {
    width: 47%;
    margin: 0 auto;
  }
}

.col-empty {
  height: 0px!important;
  margin-bottom: 0px!important;
}


.div-middle-inner {
  display: table-cell;
  vertical-align: middle;
} 

.div-middle-outer {
  height:50px;
  width:100%;
  display: table;
}

.div-middle-img {
  max-width:40px;
  max-height:40px;
}

.div-middle-text {
  margin-top:4px;
  clear:both;
}


/* CoinClothingSingle */

.cc-single {
  /* font-size: 14px; */
}

td {
  min-width:35px;
}

.link-details {
  /* font-size:14px; */
}

.link-hover:hover {
  text-decoration:underline!important;
}

.crypto-product {
  /* padding: 5px; */
  border: 1px solid #999999;
  width: 240px;
  height: 230px;
  position: relative;
  border-radius: 3px;
  text-align:center;
  float:left;
  background:url(https://cryptoclothing.cc/img/loader.gif) center center no-repeat;
  overflow: hidden;
}

.logo-hoodie {
  position: absolute;
  top: 78px;
  left:100px;
  max-width: 43px;
  max-height: 43px;
}

.logo-mug {
  position: absolute;
  top: 85px;
  left:100px;
  max-width: 63px;
  max-height: 63px;
}

.coin-tshirt {
  position: absolute;
  top: 79px;
  left: 102.5px;
  height:45px;
  width:45px;
  display: table;
}

.coin-tshirt-inner {
  display: table-cell;
  vertical-align: middle;
  max-height: 45px;
  max-width: 45px;
}

.coin-tshirt-img {
  max-width: 45px;
  max-height: 45px;
}

.coin-hoodie {
  position: absolute;
  top: 79px;
  left: 100px;
  height:45px;
  width:45px;
  display: table;
}

.coin-hoodie-inner {
  display: table-cell;
  vertical-align: middle;
  max-height: 45px;
  max-width: 45px;
}

.coin-hoodie-img {
  max-width: 45px;
  max-height: 45px;
}

.coin-mug {
  position: absolute;
  top: 88px;
  left: 105px;
  height:60px;
  width:60px;
  display: table;
}

.coin-mug-inner {
  display: table-cell;
  vertical-align: middle;
  max-height: 60px;
  max-width: 60px;
}

.coin-mug-img {
  max-width: 60px;
  max-height: 60px;
}

/* CoinClothing */

.link-details {
  /* font-size:14px; */
}

.link-hover:hover {
  text-decoration:underline!important;
}

.crypto-product {
  /* padding: 5px; */
  border: 1px solid #999999;
  width: 270px;
  height: 270px;
  /* width: 300px;
  height: 300px; */
  position: relative;
  border-radius: 3px;
  text-align:center;
  float:left;
}

.product-content {
  float:left;
  padding-left:35px;
}

.logo-hoodie {
  position: absolute;
  top: 78px;
  left:100px;
  max-width: 43px;
  max-height: 43px;
}

.logo-mug {
  position: absolute;
  top: 85px;
  left:100px;
  max-width: 63px;
  max-height: 63px;
}

.coin-tshirt {
  position: absolute;
  top: 79px;
  left: 102.5px;
  height:45px;
  width:45px;
  display: table;
}

.coin-tshirt-inner {
  display: table-cell;
  vertical-align: middle;
  max-height: 45px;
  max-width: 45px;
}

.coin-tshirt-img {
  max-width: 45px;
  max-height: 45px;
}

.coin-hoodie {
  position: absolute;
  top: 79px;
  left: 100px;
  height:45px;
  width:45px;
  display: table;
}

.coin-hoodie-inner {
  display: table-cell;
  vertical-align: middle;
  max-height: 45px;
  max-width: 45px;
}

.coin-hoodie-img {
  max-width: 45px;
  max-height: 45px;
}

.coin-mug {
  position: absolute;
  top: 88px;
  left: 105px;
  height:60px;
  width:60px;
  display: table;
}

.coin-mug-inner {
  display: table-cell;
  vertical-align: middle;
  max-height: 60px;
  max-width: 60px;
}

.coin-mug-img {
  max-width: 60px;
  max-height: 60px;
}

/* CryptoLogo */

.crypto-logo-png {
  border: 1px solid #999999;
  width: 240px;
  height: 240px;
  position: relative;
  border-radius: 3px;
  text-align:center;
  margin-bottom:7px;
  display: table;
}

.crypto-logo-png-inner {
  display: table-cell;
  vertical-align: middle;
}

.crypto-logo-svg {
  border: 1px solid #999999;
  width: 240px;
  position: relative;
  border-radius: 3px;
  text-align:center;
  margin-bottom:7px;
}


/* CryptoLogos */

.crypto-logo-product {
  border: 1px solid #999999;
  width: 240px;
  height: 230px;
  position: relative;
  border-radius: 3px;
  text-align:center;
  float:left;
  clear: both;
  vertical-align: middle;
}


/* General */

.block-margin {
  margin-bottom:60px;
}

.block-image {
  margin-top:20px;
  min-height:100px;
  background-image:url(https://cryptoclothing.cc/img/fidget-loader.gif);
  background-repeat:no-repeat;
  background-position:center center;
}

.block-rating-info {
  padding-top:3px;
}

.block-rating-info a {
  text-decoration: underline!important;
  color: #007EAB!important;
  border-bottom: 0px!important;
}

.block-rating {
  padding: 10px;
  border: 1px solid #999999;
  border-radius: 3px;
  background-color: lightgoldenrodyellow;
}

.block-product {
  padding: 10px;
  border: 1px solid #999999;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 20px;
}

.block-product-description {
  /* font-size: 14px; */
  margin-bottom: -15px;
}

.block-bottom-text {
  padding-top: 25px;
  margin-bottom: -15px;
}

.social-icon-outer {
  float:left;
  width:30px;
  margin-right: 15px;
}

.social-icon {
  fill:rgb(226, 226, 226);
}

.social-icon:hover path {
  fill: rgb(255, 255, 255);
}

.social-icon-menu {
  fill:rgb(109, 109, 109);
}

.social-icon-menu:hover path {
  fill: rgb(202, 202, 202);
}

.empty-space {
  clear:both;
  height: 20px;
}

.icon-div {
  float:left;
  width:40px;
  height:40px;
  border-radius:50%;
  background-color:#ffdada;
  border: 1px solid rgb(255, 170, 170);
  margin-right:5px;
  text-align: center;
  padding-top: 8px;
  font-size:14px;
  margin-bottom:50px;
}

.icon-div-extra {
  float:left;
  width:40px;
  height:40px;
  border-radius:50%;
  background-color:#daf0ff;
  border: 1px solid #a4c9c2;
  margin-right:5px;
  text-align: center;
  padding-top: 8px;
  font-size:14px;
  margin-bottom:50px;
}

.app-button {
  /* border:1px solid #999999; */
  /* border-radius:3px; */
  /* width:36px; */
  float:right;
  text-align:center;
  font-size:14px;
  margin-top:18px;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 4px;
  margin-left: 11px;
  vertical-align: middle;
  padding-top:2px;
  /* background-color: #f7f7f7; */

  width: 50px;
  color:white;
  border:2px solid white;
}

.app-button:hover {
  /* text-decoration: underline; */
  background-color: #FF9933;
}


h2 {
  margin-top: 0px;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}


/* Layout */

.wrapper {
  height: 100%;
}

.box-outer {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: visible;
  /* padding: 10px; */
  /* padding-left: 13px;
  padding-right: 13px; */
  background-color: white;
  line-height: 18px;
  padding-top: 0px;
  margin: 0 auto;
}

.box {
  position: relative;
  max-width: 640px;
  /* max-width: 720px; */
  margin: 0 auto;
}

.stress-toy-div {
  width:50%;
  float:left;
  margin-bottom:30px;
}

.icon-text {
  padding-left: 52px;
}


/* Custom scrollbars */

/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #c5c5c5; 
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: rgb(180, 180, 180); 
} */




/*
 * Creditly Gray Theme (gray-theme)
 */

.creditly-wrapper.gray-theme .card_type {
  float: right;
  font-style: italic;
  font-weight: normal !important;
}

.creditly-wrapper.gray-theme h3 {
  background: #424242;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
  padding: 14px;
  margin: 0;
  text-transform: uppercase;
  height: 20px;
}

.creditly-wrapper.gray-theme .credit-card-wrapper {
  padding-top: 20px;
  padding-bottom: 10px;
}

.creditly-wrapper.gray-theme {
  background: #E9E9E9;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.creditly-wrapper.gray-theme .form-control:focus {
  border-color: #888;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
}

.creditly-wrapper.gray-theme .form-control {
  border-color: #BBB;
  border-radius: 0px;
  box-shadow: none;
  -webkit-box-shadow: none;
  display: block;
  width: 92%;
  height: 26px;
  padding: 4px 4px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  background-image: none;
}

.creditly-wrapper.gray-theme .control-label {
  /* font-weight: 600; */
  margin-bottom: 4px;
  font-size: 13px;
  float:left;
}

.creditly-wrapper.gray-theme .col-sm-8 {
  padding-left: 10px;
  padding-right: 0px;
}

.creditly-wrapper.gray-theme .col-sm-4 {
  padding-left: 10px;
  padding-right: 10px;
}


/*
 * Bootstrap stuff
 */

.creditly-wrapper input.form-control.has-error {
  outline: none;
  border-color: #ff7076;
  border-top-color: #ff5c61;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.2),0 1px 0 rgba(255,255,255,0),0 0 4px 0 rgba(255,0,0,0.5);
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.2),0 1px 0 rgba(255,255,255,0),0 0 4px 0 rgba(255,0,0,0.5);
  -ms-box-shadow: inset 0 1px 2px rgba(0,0,0,0.2),0 1px 0 rgba(255,255,255,0),0 0 4px 0 rgba(255,0,0,0.5);
  -o-box-shadow: inset 0 1px 2px rgba(0,0,0,0.2),0 1px 0 rgba(255,255,255,0),0 0 4px 0 rgba(255,0,0,0.5);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.2),0 1px 0 rgba(255,255,255,0),0 0 4px 0 rgba(255,0,0,0.5);
}

.creditly-wrapper .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 2px 6px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.creditly-wrapper .form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6);
}

.creditly-wrapper label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

.creditly-wrapper input:focus::-webkit-input-placeholder {
    color: #ccc;
}

.creditly-wrapper input:focus:-moz-placeholder {
    color: #ccc;
}

.creditly-wrapper input:focus::-moz-placeholder {
    color: #ccc;
}

.creditly-wrapper input:focus:-ms-input-placeholder {
    color: #ccc;
}

.creditly-wrapper .col-sm-8 {
  width: 66.6666666666666%;
}

.creditly-wrapper .col-sm-4 {
  width: 33.3333333333333%;
}

.creditly-wrapper .col-sm-8,
.creditly-wrapper .col-sm-4 {
  float: left;
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.creditly-wrapper .form-group {
  width: 100%;
  display: table;
}

.creditly-wrapper .first-row {
  margin-bottom: 5px;
}

.creditly-wrapper .card-type {
  margin-top: 10px;
  float: right;
}





/* Mobile */

@media only screen and (max-width: 680px) {
  .box {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 0px;
    border-radius: 0px;
  }
  .right-menu {
    margin-right: 6px;
  }
  .dig {
    margin-bottom: 15px;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 18px;
    
  }
  .sticky {
    padding-left: 5px;
  }
  /* ::-webkit-scrollbar {
    width: 5px;
  } */
  .stress-toy-div {
    display:block;
    width:100%;
  }
}

button {
  background-color: #106173;
  /* background-color: #adadad; */
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1px;
  font-family: 'Work Sans', sans-serif;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 22px 8px 20px;
  border-radius: 4px;
}

button:disabled {
  background-color: #a6a6a6!important;
  cursor: auto;
}

button:hover {
  background-color: #20778a;
  /* background-color: #adadad; */
}


/* CREDITLY CREDITLY */
/* CREDITLY CREDITLY */
/* CREDITLY CREDITLY */

.checkout-errors {
  padding-left: 4px;
}

.terms-checkbox input {
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  padding: 10px;
}

/* CHECKBOX */
/* Customize the label */
.checkbox-div {
  display: block;
  position: relative;
  padding-top: 2px;
  /* cursor: pointer; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  /* left: 0; */
  height: 15px;
  width: 15px;
  background-color: rgb(247, 247, 247);
  border: 1px solid grey;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-div input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-div .checkmark:after {
  left: 3px;
  top: 0px;
  width: 7px;
  height: 9px;
  border: solid rgb(53, 53, 53);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* RADIO */
/* Customize the label */
.radio-div {
  /* position: relative;
  float: left; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

/* Hide the browser's default checkbox */
.radio-div input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiomark {
  position: absolute;
  top: 0;
  height: 12px;
  width: 12px;
  background-color: rgb(219, 219, 219);
  border: 1px solid grey;
  border-radius: 50%;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio-div input:checked ~ .radiomark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio-div .radiomark:after {
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(53, 53, 53);
}

.radiotext {
  /* padding-left: 22px; */
  font-size: 13px;
  /* margin-top: -0.5px; */
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

/* NEW */

.grid-product {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

@media screen and (max-width: 540px) {
  .grid-product {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}



.ma-banner {
    text-align:center;
      background-color:#03031F;color:white;

  /* background-image: url('https://multiavatar.com/img/thumb-world-3.png');
   */
   /* background-image: url('http://localhost:8081/img/cw-banner-bg.png'); */
   
   /* background-image: url('http://cryptoclothing.cc/img/cw-banner-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 500px; */

  background-image: url('http://cryptoclothing.cc/img/cw-alarms-top.png'), url('http://cryptoclothing.cc/img/cw-alarms-bottom.png');
  background-repeat: no-repeat, no-repeat;
  background-position: center 25px, center bottom 25px;
  background-size: 85%, 85%;

  background-color: #e5e5e5;
    border-radius:5px;
    padding-top:0px;
    width: 89%;
    max-width:630px;
    margin:0 auto;
    height: 300px;
  font-size:40px;

  display: flex; align-items: center; justify-content: center;
  line-height:110%;
  }

  @media screen and (max-width: 600px) {
    .ma-banner {
      height: 250px;
    }
  }

  @media screen and (max-width: 450px) {
    .ma-banner {
      height: 220px;
    }
  }
  
  @media screen and (max-width: 320px) {
    .ma-banner {
      height: 200px;
    }
  }
  
  .gradient-border {
  --borderWidth: 7px;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius:9px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.banner-link {
  color:#5b5b5b;
  font-weight:800;
  font-family:Montserrat, Arial, Helvetica, sans-serif;
  letter-spacing:1px;
}

.banner-link:hover, .banner-link:active {
  color:#204bca;
}

.ma-banner:hover .banner-link {
  
  color:#204bca;
}
</style>