<template>
  <div v-cloak v-if="($route.name == 'CryptoFashion') || ($route.name == 'SingleApocalypseWow') || ($route.name == 'CoinClothing')">

    <div>

      <span v-if="$route.name == 'SingleApocalypseWow'">
        <h3>Dogecoin Apocalypse WOW Meme T-Shirt</h3>
        <div style="height:30px"></div>
      </span>

      <div class="grid-product">
        
        <div class="crypto-product">

          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'">
            <img :src="'/merch/crypto-x-ai/bitcoin-x-ai-basquiat-t-shirt.png'+version" width="430" style="margin-left:-75px;margin-top:-40px;" draggable="false"  alt="Dogecoin (DOGE) Apocalypse Wow Meme T-Shirt">
          </router-link>
          
        </div>

        <div class="product-content">
          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'"><span class="product-title">Apocalypse WOW</span></router-link>
          <br>
          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'" class="link-details link-hover">Dogecoin Meme T-Shirt</router-link>
          
          <div style="height:24px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="amount" type="number" value="1" style="padding-left:4px;width:55px;height:20px;padding-top:2px;">
          </div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="size" style="width:65px;">
              <option v-for="(value, key) in products['cc-tshirt'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>
          Price: ${{price}}

          <div style="height:25px;clear:both;"></div>
          <!-- <button @click="addToCart()">Add to cart</button> -->
          <button disabled>Out of Stock</button>
        
          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </div>

      </div>

      <div style="clear:both;padding-top:40px;" v-if="$route.name == 'SingleApocalypseWow'">
        <b>Description</b>
        <br>
        <br>"Apocalypse WOW" Dogecoin meme was first spotted on <a target="_blank" class="underline" href="https://www.reddit.com/r/dogecoin/comments/2csb5p/apocalypse_wow/">Reddit</a> in 2015 after a spectacular Dogecoin price crash. At that time, Dogecoin hodlers were experiencing "the horror", as in the Francis Ford Coppola's movie Apocalypse Now, hence the meme.
        <br><br>
        The Apocalypse WOW design was re-created in high-quality in 2021, and is now available as a Dogecoin meme t-shirt.
        <br><br>
        For this Dogecoin "Apocalypse WOW" meme t-shirt, the Gildan 64000 model is a great choice. It's a classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
        <br><br>
        100% ring-spun cotton<br>
        Fabric weight: 4.5 oz/y² (153 g/m²)<br>
        Pre-shrunk for extra durability<br>
        Shoulder-to-shoulder taping<br>
        Quarter-turned to avoid crease down the center<br>
        <br>
        The male model in the above photo is wearing a size M. He's 6.2 feet (190 cm) tall, chest circumference 37.7" (96 cm), waist circumference 33.4" (85 cm). 
        <div style="height:60px;"></div>
        <b>Size guide</b>
        <br><br>
        <div>
        US sizes (In)<div id="size-guide-12" class="wysiwyg">
        <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>18</td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
        </div>
        <br>
        <div>
        Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
        <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>45.7</td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
        <br>
        Measurements are provided by suppliers.
        <br>
        Product measurements may vary by up to 2" (5 cm).
        </div>
        <div style="clear:both;height:40px;"></div>
        <b>Care instructions</b>
        <br>
        <br>      
        - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
        - Tumble dry low, or hang-dry for longest life.<br>
        - Cool iron inside-out if necessary. Do not iron decoration.<br>
        - Do not dry clean.
      </div>

      <span v-if="$route.name == 'SingleApocalypseWow'">
        <div style="clear:both;height:80px;"></div>
        <div style="border-bottom:1px solid #b1b1b1;"></div>
        <div style="clear:both;height:20px;"></div>

        <div>
          See more: <router-link to="/dogecoin" class="underline">Dogecoin (DOGE) Clothing</router-link>
        </div>
      </span>

    </div>

    <div style="clear:both;height:40px;"></div>

    <div>

      <span v-if="$route.name == 'SingleApocalypseWow'">
        <h3>Dogecoin Apocalypse WOW Meme T-Shirt</h3>
        <div style="height:30px"></div>
      </span>

      <div class="grid-product">
        
        <div class="crypto-product">

          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'">
            <img :src="'/merch/crypto-x-ai/bitcoin-x-ai-edward-munch-t-shirt.png'+version" width="430" style="margin-left:-75px;margin-top:-40px;" draggable="false"  alt="Dogecoin (DOGE) Apocalypse Wow Meme T-Shirt">
          </router-link>
          
        </div>

        <div class="product-content">
          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'"><span class="product-title">Apocalypse WOW</span></router-link>
          <br>
          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'" class="link-details link-hover">Dogecoin Meme T-Shirt</router-link>
          
          <div style="height:24px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="amount" type="number" value="1" style="padding-left:4px;width:55px;height:20px;padding-top:2px;">
          </div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="size" style="width:65px;">
              <option v-for="(value, key) in products['cc-tshirt'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>
          Price: ${{price}}

          <div style="height:25px;clear:both;"></div>
          <button @click="addToCart()">Add to cart</button>
          <!-- <button disabled>Out of Stock</button> -->
        
          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </div>

      </div>

      <div style="clear:both;padding-top:40px;" v-if="$route.name == 'SingleApocalypseWow'">
        <b>Description</b>
        <br>
        <br>"Apocalypse WOW" Dogecoin meme was first spotted on <a target="_blank" class="underline" href="https://www.reddit.com/r/dogecoin/comments/2csb5p/apocalypse_wow/">Reddit</a> in 2015 after a spectacular Dogecoin price crash. At that time, Dogecoin hodlers were experiencing "the horror", as in the Francis Ford Coppola's movie Apocalypse Now, hence the meme.
        <br><br>
        The Apocalypse WOW design was re-created in high-quality in 2021, and is now available as a Dogecoin meme t-shirt.
        <br><br>
        For this Dogecoin "Apocalypse WOW" meme t-shirt, the Gildan 64000 model is a great choice. It's a classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
        <br><br>
        100% ring-spun cotton<br>
        Fabric weight: 4.5 oz/y² (153 g/m²)<br>
        Pre-shrunk for extra durability<br>
        Shoulder-to-shoulder taping<br>
        Quarter-turned to avoid crease down the center<br>
        <br>
        The male model in the above photo is wearing a size M. He's 6.2 feet (190 cm) tall, chest circumference 37.7" (96 cm), waist circumference 33.4" (85 cm). 
        <div style="height:60px;"></div>
        <b>Size guide</b>
        <br><br>
        <div>
        US sizes (In)<div id="size-guide-12" class="wysiwyg">
        <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>18</td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
        </div>
        <br>
        <div>
        Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
        <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>45.7</td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
        <br>
        Measurements are provided by suppliers.
        <br>
        Product measurements may vary by up to 2" (5 cm).
        </div>
        <div style="clear:both;height:40px;"></div>
        <b>Care instructions</b>
        <br>
        <br>      
        - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
        - Tumble dry low, or hang-dry for longest life.<br>
        - Cool iron inside-out if necessary. Do not iron decoration.<br>
        - Do not dry clean.
      </div>

      <span v-if="$route.name == 'SingleApocalypseWow'">
        <div style="clear:both;height:80px;"></div>
        <div style="border-bottom:1px solid #b1b1b1;"></div>
        <div style="clear:both;height:20px;"></div>

        <div>
          See more: <router-link to="/dogecoin" class="underline">Dogecoin (DOGE) Clothing</router-link>
        </div>
      </span>

    </div>

    <div style="clear:both;height:40px;"></div>

    <div>

      <span v-if="$route.name == 'SingleApocalypseWow'">
        <h3>Dogecoin Apocalypse WOW Meme T-Shirt</h3>
        <div style="height:30px"></div>
      </span>

      <div class="grid-product">
        
        <div class="crypto-product">

          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'">
            <img :src="'/merch/crypto-x-ai/bitcoin-x-ai-mondrian-t-shirt.png'+version" width="430" style="margin-left:-75px;margin-top:-40px;" draggable="false"  alt="Dogecoin (DOGE) Apocalypse Wow Meme T-Shirt">
          </router-link>
          
        </div>

        <div class="product-content">
          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'"><span class="product-title">Apocalypse WOW</span></router-link>
          <br>
          <router-link :to="'/dogecoin/apocalypse-wow-meme-t-shirt'" class="link-details link-hover">Dogecoin Meme T-Shirt</router-link>
          
          <div style="height:24px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="amount" type="number" value="1" style="padding-left:4px;width:55px;height:20px;padding-top:2px;">
          </div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="size" style="width:65px;">
              <option v-for="(value, key) in products['cc-tshirt'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>
          Price: ${{price}}

          <div style="height:25px;clear:both;"></div>
          <button @click="addToCart()">Add to cart</button>
          <!-- <button disabled>Out of Stock</button> -->
        
          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </div>

      </div>

      <div style="clear:both;padding-top:40px;" v-if="$route.name == 'SingleApocalypseWow'">
        <b>Description</b>
        <br>
        <br>"Apocalypse WOW" Dogecoin meme was first spotted on <a target="_blank" class="underline" href="https://www.reddit.com/r/dogecoin/comments/2csb5p/apocalypse_wow/">Reddit</a> in 2015 after a spectacular Dogecoin price crash. At that time, Dogecoin hodlers were experiencing "the horror", as in the Francis Ford Coppola's movie Apocalypse Now, hence the meme.
        <br><br>
        The Apocalypse WOW design was re-created in high-quality in 2021, and is now available as a Dogecoin meme t-shirt.
        <br><br>
        For this Dogecoin "Apocalypse WOW" meme t-shirt, the Gildan 64000 model is a great choice. It's a classic t-shirt that compliments any outfit. It's made of a heavier cotton and the double-stitched neckline and sleeves give it more durability, so it can become an everyday favorite.
        <br><br>
        100% ring-spun cotton<br>
        Fabric weight: 4.5 oz/y² (153 g/m²)<br>
        Pre-shrunk for extra durability<br>
        Shoulder-to-shoulder taping<br>
        Quarter-turned to avoid crease down the center<br>
        <br>
        The male model in the above photo is wearing a size M. He's 6.2 feet (190 cm) tall, chest circumference 37.7" (96 cm), waist circumference 33.4" (85 cm). 
        <div style="height:60px;"></div>
        <b>Size guide</b>
        <br><br>
        <div>
        US sizes (In)<div id="size-guide-12" class="wysiwyg">
        <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>18</td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
        </div>
        <br>
        <div>
        Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
        <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>45.7</td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td></tr></tbody></table><div class="margin-top-15"></div></div></div>
        <br>
        Measurements are provided by suppliers.
        <br>
        Product measurements may vary by up to 2" (5 cm).
        </div>
        <div style="clear:both;height:40px;"></div>
        <b>Care instructions</b>
        <br>
        <br>      
        - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
        - Tumble dry low, or hang-dry for longest life.<br>
        - Cool iron inside-out if necessary. Do not iron decoration.<br>
        - Do not dry clean.
      </div>

      <span v-if="$route.name == 'SingleApocalypseWow'">
        <div style="clear:both;height:80px;"></div>
        <div style="border-bottom:1px solid #b1b1b1;"></div>
        <div style="clear:both;height:20px;"></div>

        <div>
          See more: <router-link to="/dogecoin" class="underline">Dogecoin (DOGE) Clothing</router-link>
        </div>
      </span>

    </div>
    
  </div>
</template>

<script>

export default {

  name: 'SingleCryptoAI',
  data() {
    return {

      products: {
        'cc-tshirt': {
          'name': 'T-Shirt',
          'sizesPrices': {
            'S': 24,
            'M': 24,
            'L': 24,
            'XL': 24,
            '2XL': 25,
            '3XL': 26,
          }
        }
      },

      version: '',

      amount: 1,
      color: 'Black',
      size: 'L',

      showAddedLink: false,
      sliderPic2: false,
    }
  },
  
  beforeMount() {
    // this.title = this.coin;
    this.version = assetsVersion;
  },
  
  mounted() {
  },

  computed: {
    price() {
      if (this.size == "L" || this.size == "S" || this.size == "M" || this.size == "XL" ) {
        return 24;
      }
      else if (this.size == "2XL") {
        return 25;
      }
      else if (this.size == "3XL") {
        return 26;
      }
    },
  },
  methods: {

    addToCart: function () {

        this.showAddedLink = true;

        let item_name = 'Apocalypse WOW T-Shirt';
        let item_name_full = 'Dogecoin (DOGE) Apocalypse WOW T-Shirt';
        let item_id = 'cc-doge-apocalypse-wow';
        let item_price = this.price;
        let item_size = this.size;
        let item_amount = this.amount;

        dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_price: item_price, item_size: item_size, item_amount: item_amount })
        .then((id) => {
          this.$emit('countRefresh');
        });

    },

    dressSecond() {
      this.sliderPic2 = true;
    },

    dressFirst() {
      this.sliderPic2 = false;
    },

  }
};
</script>