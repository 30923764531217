<template>
  <div v-cloak v-if="($route.name == 'CoinClothingSweatshirt') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoSweatshirts')">
    
    <div class="grid-product">
      <div>
        <router-link :to="'/'+title+'/sweatshirt'">
          <div class="crypto-product">
            <img v-if="color == 'White'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-sweatshirt.jpg'+version" width="274" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Sweatshirt White'">
            <img v-if="color == 'Black'" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-sweatshirt-black.jpg'+version" width="274" class="select-none" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') Crypto Sweatshirt Black'">
          </div>
        </router-link>
      </div>

      <div class="product-content">
        <router-link :to="'/'+title+'/sweatshirt'">
          <span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Sweatshirt</span>
        </router-link>
        <br>
        <router-link :to="'/'+title+'/sweatshirt'" class="link-details link-hover">Gildan 18000 Unisex Crew Neck Sweatshirt</router-link>
        <div style="height:15px;"></div>

        <span >
          <div class="product-cell-left">Price</div>
          <div class="product-cell-left">${{sweatshirtPrice}}</div>

          <div style="height:16px;clear:both;"></div>

          <div class="product-cell-left">Size</div>
          <div class="product-cell-left">
            <select v-model="size" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-sweatshirt'].sizesPrices" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Color</div>
          <div class="product-cell-left">
            <select v-model="color" style="width:65px;">
              <option v-for="(value, key) in $parent.$parent.products['cc-sweatshirt'].colors" :key="key" v-bind:value="key">
                {{ key }}
              </option>
            </select>
          </div>

          <div style="height:12px;clear:both;"></div>

          <div class="product-cell-left">Quantity</div>
          <div class="product-cell-left">
            <input v-model="amount" type="number" value="1" style="padding-left:4px;width:59px;padding-top:2px;">
          </div>

          <div style="height:25px;clear:both;"></div>
          
          <button v-if="available" @click="addToCart('cc-sweatshirt')">Add to cart</button>
          <button v-else disabled>Out of Stock</button>

          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;position:absolute;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingSweatshirt'">
      <b>Description</b>
      <br><br>
      This well-loved {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Gildan 18000 Unisex Sweatshirt is the perfect addition to any wardrobe. It has a crew neck, and it's made from air-jet spun yarn and quarter-turned fabric, which eliminates a center crease, reduces pilling, and gives the sweatshirt a soft, comfortable feel.
      <br><br>
      Fabric weight: 8.0 oz/y² (271.25 g/m²)
      <br>
      50/50 cotton/polyester
      <br>
      Pre-shrunk
      <br>
      1x1 athletic rib-knit collar with spandex
      <br>
      Air-jet spun yarn with a soft feel and reduced pilling
      <br>
      Double-needle stitched collar, shoulders, armholes, cuffs, and hem
      <br>
      Quarter-turned to avoid crease down the middle
      <br>
      Blank product sourced from Honduras or Mexico
      <div style="height:60px;"></div>
      <b>Size guide</b>
      <br><br>
      <div>
      US sizes (In)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td><td>4XL</td><td>5XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>27</td><td>28</td><td>29</td><td>30</td><td>31</td><td>32</td><td>33</td><td>34</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>20</td><td>22</td><td>24</td><td>26</td><td>28</td><td>30</td><td>32</td><td>34</td></tr></tbody></table><div class="margin-top-15"></div></div>
      </div></div>
      <br>
      <div>
      Metric sizes (Cm)<div id="size-guide-12" class="wysiwyg">
      <div class="size-guide"><table class="size-guide__table"><thead><tr><td></td><td>S</td><td>M</td><td>L</td><td>XL</td><td>2XL</td><td>3XL</td><td>4XL</td><td>5XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row"><span>Length</span></td><td>68.6</td><td>71.1</td><td>73.7</td><td>76.2</td><td>78.7</td><td>81.3</td><td>83.8</td><td>86.4</td></tr><tr><td class="size-guide__table-first-row"><span>Width</span></td><td>50.8</td><td>55.9</td><td>61</td><td>66</td><td>71.1</td><td>76.2</td><td>81.3</td><td>86.4</td></tr></tbody></table><div class="margin-top-15"></div></div>
      </div></div>
      <br>
      Measurements are provided by suppliers.
      <br>
      Product measurements may vary by up to 2" (5 cm).
      <div style="clear:both;height:50px;"></div>
      <b>Care instructions</b>
      <br>
      <br>      
      - Machine wash cold, inside-out, gentle cycle with mild detergent and similar colors. Use non-chlorine bleach, only when necessary. No fabric softeners.<br>
      - Tumble dry low, or hang-dry for longest life.<br>
      - Cool iron inside-out if necessary. Do not iron decoration.<br>
      - Do not dry clean.<br>
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoSweatshirt',
  props: [ 'coin', 'available' ],
  data() {
    return {
      version: '',

      size: 'L',
      color: 'White',
      amount: 1,
      showAddedLink: false,

      dress2nd: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
  },
  computed: {
    sweatshirtPrice() {
      return this.$parent.$parent.products['cc-sweatshirt'].sizesPrices[this.size]
    },
  },
  methods: {

    addToCart: function () {

      this.showAddedLink = true;

      // console.log('hoo');
      let item_name = 'Sweatshirt';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-sweatshirt-'+this.size+'-'+this.$route.params.coinId+'-'+this.color;
      let item_price = this.sweatshirtPrice;
      let item_amount = this.amount;
      let item_size = this.size;
      let item_config = { color: this.color };


      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, item_config: item_config, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>