<template>
  <div v-cloak v-if="($route.name == 'CryptoFashion') || ($route.name == 'CryptoLogosPoster')">

    <span v-if="$route.name == 'CryptoLogosPoster'">
      <h3>Crypto Logos Poster 2021</h3>
      <div style="height:30px"></div>
    </span>

    <div class="grid-product">
      
      <div class="crypto-product">

        <router-link :to="'/crypto-fashion/crypto-logos-poster'">
          <img v-if="color == 'Black'" :src="'/img/crypto-logos-poster-black-model.jpg'+version" width="270" draggable="false"  alt="Crypto Logos Poster Black">
          <img v-if="color == 'White'" :src="'/img/crypto-logos-poster-white-model.jpg'+version" width="270" draggable="false"  alt="Crypto Logos Poster White">
        </router-link>
        
      </div>

      <div class="product-content">
        <router-link :to="'/crypto-fashion/crypto-logos-poster'"><span class="product-title">Crypto Logos Poster</span></router-link>
        <br>
        <router-link :to="'/crypto-fashion/crypto-logos-poster'" class="link-details link-hover">2021 Edition</router-link>
        
        <div style="height:24px;clear:both;"></div>
        <div class="product-cell-left">Color</div>
        <div class="product-cell-left">
          <select v-model="color" style="width:65px;">
            <option v-for="(value, key) in products['cc-poster'].colors" :key="key" v-bind:value="key">
              {{ key }}
            </option>
          </select>
        </div>
        
        <div style="height:12px;clear:both;"></div>

        <div class="product-cell-left">Quantity</div>
        <div class="product-cell-left">
          <input v-model="amount" type="number" value="1" style="padding-left:4px;width:55px;height:20px;padding-top:2px;">
        </div>

        <span v-if="available">
          <div style="height:12px;clear:both;"></div>
          Price: ${{price}}

          <div style="height:25px;clear:both;"></div>
          <!-- <button @click="addToCart()">Add to cart</button> -->
          <button disabled>Out of Stock</button>
        
          <div v-if="showAddedLink" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CryptoLogosPoster'">
      <b>Description</b>
      <br><br>
      Museum-quality poster made on thick and durable matte paper. A statement in any room, each poster is giclée-printed on archival, acid-free paper that yields brilliant prints to brighten up any room.
      <br><br>
      <ul>
        <li>Size: 50x70 cm (19.7x27.6 in)</li>
        <li>Paper thickness: 10.3 mil</li>
        <li>Paper weight: 5.57 oz/y² (189 g/m²)</li>
        <li>Giclée printing quality</li>
        <li>Opacity: 94%</li>
        <li>ISO brightness: 104%</li>
        <li>Blank product sourced from Japan</li>
        <li>2021 January Edition</li>
      </ul>
    </div>

    <span v-if="$route.name == 'CryptoLogosPoster'">
      <div style="clear:both;height:80px;"></div>
      <div style="border-bottom:1px solid #b1b1b1;"></div>
      <div style="clear:both;height:20px;"></div>

      <div>
        See more: <router-link to="/crypto-fashion" class="underline">Crypto Fashion</router-link>
      </div>
    </span>

  </div>
</template>

<script>

export default {

  name: 'CryptoPoster',
  data() {
    return {

      products: {
        'cc-poster': {
          'name': 'Crypto Logos Poster',
          'sizesPrices': {
            '50x70': 28,
          },
          'shipping': {
            '1st': 5,
            'extra': 2,
          },
          'colors': {
            'Black': 'Black',
            'White': 'White'
          },
        },
      },

      version: '',
      inner: '',

      amount: 1,
      color: 'Black',

      showAddedLink: false,
      sliderPic2: false,
    }
  },
  
  beforeMount() {
    // this.title = this.coin;
    this.version = assetsVersion;
  },
  
  mounted() {
  },

  computed: {
    price() {
      return this.products['cc-poster'].sizesPrices['50x70']
    },
  },
  methods: {

    addToCart: function () {

        this.showAddedLink = true;

        let item_name = 'Poster';
        let item_name_full = 'Crypto Logos Poster';
        let item_id = 'cc-poster-'+this.color;
        let item_price = this.price;
        let item_amount = this.amount;
        let item_config = { color: this.color };

        dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_price: item_price, item_amount: item_amount, item_config: item_config })
        .then(() => {
          this.$emit('countRefresh');
        });

    },

    dressSecond() {
      this.sliderPic2 = true;
    },

    dressFirst() {
      this.sliderPic2 = false;
    },

  }
};
</script>