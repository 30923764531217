<template>
  <div v-cloak v-if="($route.name == 'CoinClothingDress') || ($route.name == 'CoinClothing') || ($route.name == 'CryptoDresses')">
    
    <div class="grid-product">

      <div class="crypto-product">

        <div class="photo-slider">
          <div style="top:10px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: !dress2nd }" @click="dress2nd = false"></div>
          <div style="top:40px;" class="photo-slider-btn" v-bind:class="{ photoSliderBtnActive: dress2nd }" @click="dress2nd = true"></div>
        </div>

        <router-link :to="'/'+title+'/dress'">
          <img v-show="!dress2nd" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-dress-front.jpg'+version" width="270" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') All-Over Print Crypto Dress'">
          <img v-show="dress2nd" :src="'/'+'merch/'+title+'-'+$parent.$parent.coins[title].symbol.toLowerCase()+'-dress-back.jpg'+version" width="270" draggable="false"  :alt="$parent.$parent.coins[title].name +' ('+ $parent.$parent.coins[title].symbol +') All-Over Print Crypto Dress'">
        </router-link>
        
      </div>

      <div class="product-content">
        <router-link :to="'/'+title+'/dress'"><span class="product-title">{{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) Dress</span></router-link>
        <br>
        <router-link :to="'/'+title+'/dress'" class="link-details link-hover">All-Over Print</router-link>
        <br>
        <br>
        
        <span>
          Price: ${{dressPrice}}
          <br>
          <br>
          Size:
          <select v-model="dressSize">
            <option v-for="(value, key) in $parent.$parent.products['cc-dress'].sizesPrices" :key="key" v-bind:value="key">
              {{ key }}
            </option>
          </select>
          &nbsp;
          Quantity: <input type="number" v-model="dressAmount" value="1" style="width:40px;">
          <div style="height:25px;"></div>
          <!-- <button @click="addToCart('cc-dress')">Add to cart</button> -->
          <button disabled>Out of Stock</button>
        
          <div v-if="showAddedLinkDress" style="margin-top:10px;font-size:14px;">
            Added to <router-link to="/shopping-cart" class="underline">Shopping Cart</router-link>
          </div>
        </span>
      </div>

    </div>

    <div style="clear:both;padding-top:40px;" v-if="$route.name == 'CoinClothingDress'">

      <b>Description</b>
      <br><br>
      This all-over print dress has {{ $parent.$parent.coins[title].name }} ({{ $parent.$parent.coins[title].symbol }}) logo printed all over the dress surface. The design is continuous from one side to another.
      <br><br>
      All-over printing is a printing process where the design is printed on paper with dye ink and then transferred directly onto the fabric with heat. All-over printing allows to cover all of the garment with the design instead of, for example, DTG printing that has a smaller area to work with.
      <br><br>
      All-over print products are also referred to as cut & sew products. In this case, designs are printed on fabric, which then gets cut and hand-sewn into the particular product.
      <br><br><br>
      <b>Size guide</b>
      <br><br>
      Inches
      <br>
      <table class="size-guide__table"><thead><tr><td></td><td>XS</td><td>S</td><td>M</td><td>L</td><td>XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row">Chest</td><td>33 ⅛</td><td>34 ⅝</td><td>36 ¼</td><td>39 ⅜</td><td>42 ½</td></tr><tr><td class="size-guide__table-first-row">Waist</td><td>25 ¼</td><td>26 ¾</td><td>28 ⅜</td><td>31 ½</td><td>34 ⅝</td></tr><tr><td class="size-guide__table-first-row">Hip</td><td>35 ⅜</td><td>37  </td><td>38 ⅝</td><td>41 ¾</td><td>44 ⅞</td></tr></tbody></table>
      <br><br>
      Centimetres
      <br>
      <table class="size-guide__table"><thead><tr><td></td><td>XS</td><td>S</td><td>M</td><td>L</td><td>XL</td></tr></thead><tbody><tr><td class="size-guide__table-first-row">Chest</td><td>84</td><td>88</td><td>92</td><td>100</td><td>108</td></tr><tr><td class="size-guide__table-first-row">Waist</td><td>64</td><td>68</td><td>72</td><td>80</td><td>88</td></tr><tr><td class="size-guide__table-first-row">Hip</td><td>90</td><td>94</td><td>98</td><td>106</td><td>114</td></tr></tbody></table>
      <br>
      Measurements are provided by suppliers.
      <br>
      Product measurements may vary by up to 2" (5 cm).
    </div>

  </div>
</template>

<script>

export default {
  name: 'CryptoDress',
  props: [ 'coin' ],
  data() {
    return {
      version: '',
      
      tshirtSize: 'L',
      hoodieSize: 'L',
      mugSize: '11oz',
      dressSize: 'M',
      dressAmount: 1,
      stickerAmount: 1,
      stickerSize: '4x4',
      tshirtAmount: 1,
      hoodieAmount: 1,
      mugAmount: 1,
      showAddedLinkTshirt: false,
      showAddedLinkDress: false,
      showAddedLinkHoodie: false,
      showAddedLinkMug: false,
      showAddedLinkSticker: false,
      dress2nd: false,
      sticker2nd: false,
    }
  },
  beforeMount() {
    this.title = this.coin;
    this.version = assetsVersion;
  },
  mounted() {
  },
  computed: {
    dressPrice() {
      return this.$parent.$parent.products['cc-dress'].sizesPrices[this.dressSize]
    }
  },
  methods: {

    addToCart: function() {

      this.showAddedLinkDress = true;

      // console.log('dre');
      let item_name = 'Dress';
      let item_name_full = this.$parent.$parent.coins[this.coin].name+' ('+this.$parent.$parent.coins[this.coin].symbol+') ' + item_name;
      let item_id = 'cc-dress-'+this.dressSize+'-'+this.coin;
      let item_price = this.dressPrice;
      let item_amount = this.dressAmount;
      let item_size = this.dressSize;


      dexie.products.put({item_id: item_id, item_name: item_name, item_name_full: item_name_full, item_size: item_size, item_price: item_price, item_amount: item_amount, coin_id: this.coin })
      .then((id) => {
        this.$parent.$emit('countRefresh');
      });
    },

    dressSecond() {
      this.dress2nd = true;
    },

    dressFirst() {
      this.dress2nd = false;
    },

  }
};
</script>