<template>
  <div class="header">
    
    <!-- <div class="top"></div> -->

    <div class="header-inner">
        
      <!-- Left side -->
      <div class="header-left;">

        <!-- <div @click="toTop()" style="float:left;margin-top:2px;margin-right:12px;">
          <router-link to="/">
            <div style="width:25px;height:25px;">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.14 571.51"><title>crypto-logos logo</title><path d="M112.57,132.32a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-49 -111)" style="fill:#f7931a;fill-rule:evenodd"/><path d="M306.07,333.75a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-49 -111)" style="fill:#f7931a;fill-rule:evenodd"/><path d="M112.57,535.19a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-49 -111)" style="fill:#f7931a;fill-rule:evenodd"/><path d="M499.57,132.32a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-49 -111)" style="fill:#f7931a;fill-rule:evenodd"/><path d="M499.57,535.19a63,63,0,1,1-63,63,63,63,0,0,1,63-63h0Z" transform="translate(-49 -111)" style="fill:#f7931a;fill-rule:evenodd"/><polygon points="387 285.75 450.57 201.44 514.14 285.75 450.57 370.07 387 285.75 387 285.75" style="fill:#3d3d3d;fill-rule:evenodd"/><polygon points="0 285.75 63.57 201.44 127.14 285.75 63.57 370.07 0 285.75 0 285.75" style="fill:#3d3d3d;fill-rule:evenodd"/><polygon points="193.5 84.32 257.07 0 320.64 84.32 257.07 168.63 193.5 84.32 193.5 84.32" style="fill:#3d3d3d;fill-rule:evenodd"/><polygon points="193.5 487.19 257.07 402.87 320.64 487.19 257.07 571.51 193.5 487.19 193.5 487.19" style="fill:#3d3d3d;fill-rule:evenodd"/></svg>
            </div>
          </router-link>
        </div> -->

        <!-- #375471 -->
        <div @click="toTop()"  style="float:left;padding-top:14px;">

          <!-- <div v-if="$route.name == 'CryptoLogos' || $route.name == 'CryptoLogo'" style="position:absolute;font-size:10px;margin-left:137px;margin-top:0px;">
            <router-link to="/crypto-logos" title="Crypto Logos">
              Logos
            </router-link>
          </div> -->

          <router-link class="logo header-hover" to="/" style="display:relative;">

            <div style="max-width: 640px; margin: 0px auto;">
              <div style="float: left; margin-top: -2px;">
                <div style="float: left; margin-top: -2px; margin-right: 12px;">
                  <a href="/" class="router-link-exact-active router-link-active">
                    <div style="width: 33px; height: 33px;">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.5 67.5" class="svg-logo">
                        <path d="M9.9 9.9a33.8 33.8 0 1 1 0 47.7 33.8 33.8 0 0 1 0-47.7z" fill="#fdffa3" fill-rule="evenodd"></path>
                        <path d="M51.3 51.3a24.7 24.7 0 1 1 0-35m-7.7 27.2a13.8 13.8 0 1 1 0-19.6" fill="none" stroke="#000" stroke-miterlimit="2.6" stroke-width="1.8"></path>
                      </svg>
                    </div>
                  </a>
                </div>
                <div style="float: left;">
                  <a href="/" class="logo router-link-exact-active router-link-active">
                    Crypto Clothing
                  </a>
                </div>
              </div>
            </div>

            <!-- <div style="display:inline;height:30px;"><img src="img/cc-logo.png" width="30"></div>
            <div style="display:inline;height:30px;">Crypto Clothing</div> -->
          </router-link>

          <!-- <div>cc</div> -->
          
          <!-- <router-link to="/faq" title="FAQ" style="display:inline-block;float:left;margin-left:12px;margin-top:7px;">
            <div  class="faq-btn">
              <div style="position:absolute;margin-left:4px;margin-top:-3px;transform: rotate(13deg);">?</div>
            </div>
          </router-link> -->
        </div>
      </div>

      <!-- Right side -->
      <router-link to="/shopping-cart" title="Shopping Cart" style="float:right;padding-top:15px;width:32px;margin-left:12px;">
        <div style="vertical-align:middle;float:left;" class="header-hover-svg">
          <svg width="35" height="30" viewBox="0 -31 512.00033 512">
            <path d="m166 300.003906h271.003906c6.710938 0 12.597656-4.4375 14.414063-10.882812l60.003906-210.003906c1.289063-4.527344.40625-9.390626-2.433594-13.152344-2.84375-3.75-7.265625-5.964844-11.984375-5.964844h-365.632812l-10.722656-48.25c-1.523438-6.871094-7.617188-11.75-14.648438-11.75h-91c-8.289062 0-15 6.710938-15 15 0 8.292969 6.710938 15 15 15h78.960938l54.167968 243.75c-15.9375 6.929688-27.128906 22.792969-27.128906 41.253906 0 24.8125 20.1875 45 45 45h271.003906c8.292969 0 15-6.707031 15-15 0-8.289062-6.707031-15-15-15h-271.003906c-8.261719 0-15-6.722656-15-15s6.738281-15 15-15zm0 0" /><path d="m151 405.003906c0 24.816406 20.1875 45 45.003906 45 24.8125 0 45-20.183594 45-45 0-24.8125-20.1875-45-45-45-24.816406 0-45.003906 20.1875-45.003906 45zm0 0" /><path d="m362.003906 405.003906c0 24.816406 20.1875 45 45 45 24.816406 0 45-20.183594 45-45 0-24.8125-20.183594-45-45-45-24.8125 0-45 20.1875-45 45zm0 0" />
          </svg>
        </div>
        <div style="position:absolute;top:16px;color:#fff;width:20px;text-align:center;padding-left:10px;float:left;font-weight:600;font-size:12px;pointer-events:none;">
          {{ shoppingCount }}
        </div>
      </router-link>

      <!-- <router-link to="/about">
        <div class="button-header">
          About
        </div>
      </router-link> -->

      <!-- <router-link style="float:right;padding-top:14px;text-align:center;" to="/crypto-merch">
        <div style="width:77px;" class="header-button">
          CRYPTO
          <br>
          MERCH
        </div>
      </router-link> -->

    </div>

  </div>
</template>

<script>

  export default {

    data() {
      return {
        homePath: homePath,
        buttonContent: 'Shopping cart'
      }
    },

    methods: {
      toTop: function() {
        window.scrollTo(0,0);
        if (this.$parent.shoppingCart) {
          this.$parent.shoppingCart = false;
          this.$router.push(this.homePath);
        }
      },
    },

    mounted: function() {

    },
    
    props: ['shoppingCount'],
  }

</script>

<style>

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  max-width: 100%;
  margin: 0 auto;
  z-index: 10;
  height: 61px;
  background-color: #F1F1F2;
  /* background-color:#848484; */
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.1), 0 2px 10px 0 rgba(0,0,0,.15);
  background-image: url('https://cryptoclothing.cc/img/adding-some-noise.png');
  /* background-color: #dadada; */
  margin-bottom: 60px;
}

.header-inner {
  max-width:640px;
  /* max-width:720px; */
  margin:0 auto;
}

.header-left {
  float:left;
  margin-top:15px;
}

@media only screen and (max-width: 680px) {
  .header {
    padding-left: 23px;
    padding-right: 23px;
  }
}

.header-button {
  height:26px;
  /* color:#e3ffff; */
  color: white;
  border:2px solid white;
  /* border:2px solid #e3ffff; */
  line-height:94%;font-size:13px;padding-top:2px;letter-spacing:1px;font-weight:600;
}

.header-button:hover {
  /* background-color: rgb(255, 167, 79); */
  /* background-color: #568b90; */
  border:2px solid #e3ffff;
  color:#e9e9e9;
}

#svg-shopping-cart {
  fill:#fff;
}

#svg-shopping-cart:hover {
  fill:#e3ffff!important;
}

.header-hover:hover {
  color:#e3ffff!important;
}

.header-hover-svg svg {
  /* fill: #fff; */
  fill: #555;
  display: block;
  pointer-events: bounding-box;
}

/* .header-hover-svg:hover svg {
  fill:#e3ffff!important;
} */

.header-hover-svg svg:hover {
  fill:#777!important;
}
/* .logo {
  width:180px;
} */

.logo {
  float:left;
  color:#222!important;
  margin-left:-2px;
  font-family: 'Montserrat', sans-serif;
  font-size:17px;
  margin-top:3px;
  border-bottom:0px!important;
}

@media screen and (max-width: 600px) {
  .logo {
    font-size:16px;
  }
}

.button-header {
  float:right;
  margin-top:18px;
  font-size:13px;
  border:1px solid rgb(171, 171, 171);
  width:70px;
  height:22px;
  text-align:center;
  margin-right:10px;
  cursor: pointer;
  border-radius: 3px;
  padding-right: 1px;
}

.button-header:hover {
  background-color:rgb(228, 228, 228);
}

@media screen and (max-width: 400px) {
  .button-header {
    width: 60px;
    margin-right: 0px;
  }
}

.faq-btn {
  width:16px;
  height:16px;
  border-radius:50%;
  background-color:white;
  color:#3D666A;
  font-weight:bold;
  text-align:center;
  font-size:13px;
}

.faq-btn:hover {
  background-color:#e3ffff;
}

.button-new {
  float:right;
  width:22px;
  height:22px;
  margin-right:30px;
  margin-top:21px;
}

.store-icon {
  width: 41px;
  margin-top: -3px;
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .store-icon {
    margin-right: 5px;
  }
}
</style>