<template>

  <div class="product-container">

    <div class="card">
      
      <div class="card-image">
        <span v-if="item_name == 'T-shirt'">
          <img v-if="item_config.color == 'White'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-t-shirt.jpg'" class="sc-product-img">
          <img v-if="item_config.color == 'Black'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-t-shirt-black.jpg'" class="sc-product-img">
        </span>
        <span v-if="item_name == 'HODL T-shirt'">
          <img v-if="item_config.color == 'White'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-hodl-t-shirt-white.jpg'" class="sc-product-img">
          <img v-if="item_config.color == 'Black'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-hodl-t-shirt-black.jpg'" class="sc-product-img">
        </span>
        <img v-if="item_name == 'Dress'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-dress-front.jpg'" class="sc-product-img">
        <span v-if="item_name == 'Hoodie'">
          <img v-if="item_config.color == 'White'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-hoodie.jpg'" class="sc-product-img">
          <img v-if="item_config.color == 'Black'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-hoodie-black.jpg'" class="sc-product-img">
        </span>
        <span v-if="item_name == 'Sweatshirt'">
          <img v-if="item_config.color == 'White'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-sweatshirt.jpg'" class="sc-product-img">
          <img v-if="item_config.color == 'Black'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-sweatshirt-black.jpg'" class="sc-product-img">
        </span>
        <span v-if="item_name == 'Long Sleeve T-Shirt'">
          <img v-if="item_config.color == 'White'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-longsleeve-t-shirt.jpg'" class="sc-product-img">
          <img v-if="item_config.color == 'Black'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-longsleeve-t-shirt-black.jpg'" class="sc-product-img">
        </span>
        <img v-if="item_name == 'Mug'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-coffee-mug.jpg'" class="sc-product-img">
        <img v-if="item_name == 'Sticker'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-sticker.jpg'" class="sc-product-img">
        <img v-if="item_name == 'Backpack'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-backpack-1.jpg'" class="sc-product-img" style="margin-top:-2px;">
        <img v-if="item_name == 'Mask'" :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-neck-gaiter-1.jpg'" class="sc-product-img">
        <span v-if="item_name == 'Poster'">
          <img v-if="item_config.color == 'Black'" src="img/crypto-logos-poster-black-model.jpg" class="sc-product-img">
          <img v-if="item_config.color == 'White'" src="img/crypto-logos-poster-white-model.jpg" class="sc-product-img">
        </span>
        <span v-if="item_name == 'Apocalypse WOW T-Shirt'">
          <img src="merch/dogecoin-doge-apocalypse-wow-meme-t-shirt.jpg" class="sc-product-img">
        </span>
        <span v-if="item_name == 'Laptop Sleeve'">
          <!-- <img v-if="item_config.color == 'Black'" src="img/crypto-fashion-laptop-case-sleeve-15-in-black.jpg" class="sc-product-img"> -->
          <img :src="'/'+'merch/'+coin_id+'-'+coins[coin_id].symbol.toLowerCase()+'-laptop-sleeve-white.jpg'" class="sc-product-img">
        </span>
      </div>
      
      <!-- Move icon -->
      <div class="card-move noselect" @click="cardMove()" title="Remove product">
        <svg class="svg-icon">
          <use xlink:href="#svg-clear"></use>
        </svg>
      </div>
      
      <div class="product-details" style="height:100%;">

        <span v-if="typeof(item_config) !== 'undefined'">
          <span v-if="item_name == 'Poster'">
            <b v-if="item_config.color == 'White'">Crypto Logos Poster | White</b>
            <b v-if="item_config.color == 'Black'">Crypto Logos Poster | Black</b>
          </span>
          <span v-else-if="item_name == 'LaptopSleeve'">
            <b v-if="item_config.color == 'White'">Crypto Laptop Sleeve | White</b>
            <b v-if="item_config.color == 'Black'">Crypto Laptop Sleeve | Black</b>
          </span>
          <span v-else>
            <b v-if="item_config.color == 'White'">{{ coins[coin_id].name }}&nbsp;({{ coins[coin_id].symbol }})&nbsp;{{ item_name }}</b>
            <b v-if="item_config.color == 'Black'">{{ coins[coin_id].name }}&nbsp;({{ coins[coin_id].symbol }})&nbsp;{{ item_name }} | Black</b>
          </span>
        </span>
        <span v-else-if="item_name == 'Apocalypse WOW T-Shirt'">
          <b>Dogecoin (DOGE) Apocalypse WOW Meme T-shirt</b>
        </span>
        <span v-else>
          <b>{{ coins[coin_id].name }}&nbsp;({{ coins[coin_id].symbol }})&nbsp;{{ item_name }}</b>
        </span>
        <br>
        <span v-if="item_name != 'Backpack' && item_name != 'Mask' && item_name != 'Poster' && item_name != 'LaptopSleeve' && item_name != 'ToteBag'">
          Size: {{item_size}}
        </span>
        <span v-else-if="item_name == 'Poster'">
          Size: 50x70 cm (19.7x27.6 in)
        </span>
        <span v-else-if="item_name == 'ToteBag'">
          Size: 15x15 in
        </span>
        <span v-else-if="item_name == 'LaptopSleeve'">
          Size: 15 in
        </span>
        <span v-else>
          One size
        </span>
        <br>
        Quantity: {{ item_amount }}
        <br>
        Price: ${{ item_price }}
        <br>
        Sum: ${{ item_price*item_amount }}
      </div>
    
    </div>

    <div style="clear:both;height:10px;"></div>

  </div>

</template>

<script>
  export default {
    data: function() {
      return {
        coins: {},
      }
    },
    beforeMount() {
      this.coins = cc_coins;
    },
    methods: {
      cardMove: function () {
        // delete note from local db
        dexie.products.where('item_id').equals(this.item_id).delete()
        .then((id) => {
          this.$emit('digsRefresh');
        });
      },
    },
    props: ['item_id', 'item_name', 'item_amount', 'item_price', 'item_size', 'coin_id', 'item_config'],
  }
</script>

<style scoped>
  .card-image {
    float:left;
    background:url(https://cryptoclothing.cc/img/loader.gif) center center no-repeat;
    min-width: 140px;
    min-height: 138px;
  }

  .product-container {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .sc-product-img {
    max-width: 140px;
    margin-bottom: -11px;
    margin-top:-2px;
  }

  .mini {
    height: 39px!important;
  }
  
  .card {
    border: 1px solid #b6b6b6;
    /* box-shadow: 0px 2px 5px 1px #adadad9e; */
    /* box-sizing: content-box; */
    margin-bottom: 0px;
    /* border-radius: 4px; */
    position: relative;
    min-height: 50px;
    overflow: hidden;
  }

  .product-details {
    padding-left: 10px;
    padding-top: 13px;
    float:left;
  }

  .sc-product-img {
    max-width: 140px;
    max-height: 140px;
    padding-bottom:5px;
  }

  @media only screen and (max-width: 600px) {
    .product-details {
      clear: both;
      display: block;
      padding: 10px;
    }
    .card {
      max-width: 300px;
    }
    .sc-product-img {
      max-width: 170px;
      max-height: 170px;
      margin-bottom: -10px;
    }
    .product-container {
      text-align: center;
    }
    .product-details {
      float:none;
    }
    .card-image {
      float: none;
    }
  }

  .card-errors {
    border: 1px solid #b6b6b6;
    border-top: 0px;
    min-height:35px;
    padding: 5px;
  }

  @media only screen and (max-width: 470px)  {
    .card-errors {
      min-height:70px;
    }
    .icon-errors {
      margin-top: 3px;
      margin-bottom: 3px;
    }
    /* .card-image */
  }

  .card-errors-selected {
    border: 1px solid #b6b6b6;
    border-top: 0px;
    min-height:35px;
    padding: 5px;
    padding-top: 6px;
    font-size:14px;
    font-family: 'Times New Roman', Times, serif;
  }

  /* .card-text {
    position: relative;
    outline: none;
    min-height: 38px;
  } */

  .svg-icon {
    width: 100%;
    height: 100%;
    display: block;
  }

  .card-move {
    /* box-sizing: content-box; */
    cursor: pointer;
    position: absolute;
    height: 11px;
    width: 11px;
    right: 1px;
    top: 2px;
    padding: 2px;
  }

  .card-resize {
    /* box-sizing: content-box; */
    cursor: pointer;
    position: absolute;
    height: 8px;
    width: 8px;
    right: 1px;
    bottom: 2px;
    padding: 2px;
    
  }

  textarea {
    width: 100%;
    border: 0px;
    box-sizing: border-box;
    outline: none;
    resize: vertical;
    font-family: 'Times New Roman', Times, serif;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-size: 15px;
    overflow: hidden!important;
    resize: none;
  }

  @media only screen and (max-width: 630px) {
    textarea {
      font-size: 14px;
    }
  }


  .textarea {
    width: 100%;
    border: 0px;
    box-sizing: border-box;
    outline: none;
    /* resize: vertical; */
    font-family: 'Times New Roman', Times, serif;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-size: 15px;
    /* overflow: hidden!important;
    resize: none; */
  }


  .icon-div-card-errors {
    background-color:#ffdada!important;
    border: 1px solid rgb(255, 170, 170)!important;
  }

  .icon-div-card-errors-extra {
    background-color:#daf0ff!important;
    border: 1px solid #a4c9c2!important;
  }

  .icon-div-card-errors-off {
    background-color:#e2e2e2;
    border: 1px solid rgb(199, 199, 199);
    margin-right:5px;
    text-align: center;
    font-size:11px;
    cursor: pointer;
    float:left;
    width:25px;
    height:25px;
    border-radius:50%;
  }

  .circle {
    width:15px;
    height:15px;
    border-radius:50%;
    background-color:#ffdada;
    border: 2px solid rgb(255, 170, 170);
    cursor: pointer;
    float:left;
    margin-top: 4px;
    margin-right: 4px;
    margin-left: 2px;
  }

  .circle-green {
    background-color:#c8ffaf!important;
    border: 2px solid rgb(38, 226, 0)!important;
  }

  .error-title {
    float:left;
    text-decoration: underline;
  }

  .error-title-grey {
    color: #b4b4b4;
  }
</style>