import Vue from 'vue'
import App from './App.vue'
import router from './router'

// router.beforeEach((to, from, next) => {
//   // console.log("NAME: ", to.name);
//   if (to.name == 'CoinClothing') {
//     document.title = "Multiavatar ID: " + $route.params.coinId;
//   }
//   else {
//     document.title = to.meta.title;
//   }
//   next()
// })



// import axios from 'axios';
// import VueAxios from 'vue-axios';
// Vue.use(VueAxios, axios);

import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import VueHead from 'vue-head'
Vue.use(VueHead)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
